@font-face {
  font-family: 'Event Horizon Medium';
  font-style: normal;
  font-weight: normal;
  src:
    local('Event Horizon Medium'),
    url('./assets/Fonts/Event\ Horizon.woff') format('woff');
}

@font-face {
  font-family: 'ammonite';
  src: url('./assets/Fonts/ammonite.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --black: #131313;
  --white: #ffffff;
  --grey: #cacaca;
  --box-shadow-color: 10px 10px 20px #00000017;
  --box-shadow-black: 10px 10px 20px #00000017;
  --border: 1px solid #131313;
  --border-black: 1px solid #131313;
  --border-white: 1px solid white;
  --border-goldenrod: 2px solid goldenrod;
  --border-bold: 2px solid #131313;
  --border-bold-black: 2px solid #131313;
  --border-bold-white: 2px solid #ffffff;
  --transition: 0.5s ease;
}

.App {
  font-family: 'Event Horizon Medium', monospace;
  display: flex;
  flex-direction: column;
  min-width: 344px;
  box-sizing: border-box;
  z-index: 1;
  min-height: 100vh;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
}

* {
  z-index: 1;
}

html {
  background: var(--grey);
  color: var(--text-color);
  transition: color var(--transition) transform var(--transition);
}

html::before {
  content: '';
  position: fixed;
  height: 100%;
  width: 100%;
  background: var(--black);
  transform: translateX(-100%);
  transition: transform var(--transition);
  z-index: 0;
}

.darkmode::before {
  transform: translateX(0);
}

/* navbar */

.navbar {
  width: 100%;
  height: 80px;
  background-color: goldenrod;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 4;
  min-width: 350px;
  border-bottom: solid var(--text-color);
  border-width: 5px;
  box-shadow: 5px 5px 10px #1313130a;
  transition: border-color var(--transition);
}

.cartIconContainer {
  margin-right: 20px;
}

.cartIconWrapper {
  position: relative;
  display: inline-block;
}

.cartItemsCount {
  font-family: 'Event Horizon Medium';
  position: absolute;
  top: -5px;
  right: -15px;
  background-color: black;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
}

.links {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.links a {
  text-decoration: none;
  color: black;
  font-size: 25px;
  transition: transform 0.3s ease;
}

.links a:hover {
  transform: scale(1.1);
}

.navbar .logo img {
  height: 70px;
  transition: transform 0.3s ease;
  margin-left: 10px;
}

.navbar .logo img:hover {
  height: 70px;
  transform: scale(1.1);
}

/* navbar ends*/

/* themeToggle starts*/

.hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.toggleContainer {
  display: flex;
  justify-content: right;
  margin-top: 130px;
  margin-right: 20px;
  z-index: 3;
}

.toggle-wrapper {
  width: 75px;
  display: flex;
  align-items: flex-end;
}

.toggle {
  height: 35px;
  width: 75px;
  background: var(--text-color);
  border-radius: 40px;
  position: absolute;
  margin: auto;
  cursor: pointer;
  transition: background var(--transition);
}

.toggle::before {
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  margin: 5px 10px;
  position: absolute;
  z-index: 2;
  transform: translate(0);
  background: var(--background-color);
  transition:
    transform var(--transition),
    background var(--transition);
}

.toggle.enabled::before {
  transform: translateX(32px);
  background-color: var(--background-color);
}

.toggle input {
  position: absolute;
  top: 0;
  opacity: 0;
}

.toggle .icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  margin: 0 5px;
}

.toggle .icons svg {
  height: 20px;
  width: 20px;
  z-index: 0;
}

/* themeToggle ends*/

/* loginBtn starts */

#loginBtn {
  font-size: 16px;
  margin-right: 20px;
}

#logoutBtn,
#profileBtn {
  font-size: 20px;
  color: #131313;
  margin-top: 20px;
}

.back-arrow svg {
  cursor: pointer;
  margin-left: 20px;
  font-size: 50px;
}

.back-arrow svg:hover {
  transform: scale(1.25);
}
