#pleaseLogIn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  flex-grow: 1;
  transform: translateY(-33%);
}

#profileContainer {
  display: flex;
  justify-content: center;
  font-size: 20px;
  flex-grow: 1;
  color: var(--text-color);
  transition: color 0.3s ease;
  width: 100%;
  margin-top: 80px;
}

.userInfo {
  display: flex;
  flex-direction: column;
}

.noOrders {
  text-align: center;
}

#ordersContainer {
  overflow: auto;
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: color 0.3s ease;
  color: var(--text-color);
}

.orderItemsTable {
  border-collapse: collapse;
  table-layout: fixed;
  margin: 0px 5px;
  width: calc(100% - 20px);
  box-sizing: border-box;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.headerRow {
  padding: 8px;
  font-size: 18px;
  border-bottom: var(--border);
  display: flex;
  align-items: center;
  transition: border-bottom 0.3s;
  margin-bottom: 10px;
  color: var(--text-color);
  transition:
    color 0.3s ease,
    border-bottom 0.3s ease;
}

.orderItemsTable th {
  flex-basis: 50%;
}

.orderItem {
  min-width: 350px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 0px;
  border-radius: 8px;
  margin: 2px 0px;
  background-color: rgb(100, 100, 100, 0.2);
}

.orderItem:hover {
  background-color: rgb(100, 100, 100, 0.4);
}

.orderItem.expanded {
  background-color: rgb(100, 100, 100, 0.4);
}

.orderItem td {
  flex-basis: 33%;
  display: flex;
  justify-content: center;
}

.orderDetailsRow {
  width: 100%;
  background-color: rgb(100, 100, 100, 0.2);
  padding: 8px 0px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  border-collapse: collapse;
}

.orderDetailsTable {
  border-collapse: collapse;
  table-layout: fixed;
  box-sizing: border-box;
  width: 100%;
  font-size: 18px;
}

.orderDetailsTable tr {
  display: flex;
  align-items: center;
}

.orderDetailsTableHeader th {
  padding-bottom: 5px;
}

.orderDetailsTable td {
  display: flex;
  flex-basis: 25%;
  justify-content: center;
  align-items: center;
}

.orderDetailsImage {
  width: 40px;
  margin-left: 0px;
  border-radius: 5px;
}
