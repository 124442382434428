.categoryPicker {
  position: relative;
  width: 200px;
  z-index: 5;
}

.dropdown-header {
  background-color: transparent;
  position: relative;
  border: var(--border-bold);
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  font-family: 'Event Horizon Medium', monospace;
  box-shadow: var(--box-shadow-black);
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    border-color 0.3s ease;
}

.dropdown-header:hover {
  background-color: var(--text-color);
  color: var(--background-color);
  border: var(--border-bold);
  cursor: pointer;  
}

.arrow {
  transition: transform 0.3s ease;
  position:absolute;
  right: 12px;
}

.arrow.open {
  transform: rotate(180deg);
}

.dropdown-list {
  position: absolute;
  width: 100%;
  background-color: var(--background-color);
  border: var(--border-bold);
  border-radius: 5px;
  box-shadow: var(--box-shadow-black);
  margin-top: 5px;
  padding: 0;
  list-style: none;
  z-index: 10;
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    border-color 0.3s ease;
}

.dropdown-list li {
  padding: 8px 12px;
  cursor: pointer;
  text-align: center;
}

.dropdown-list li:hover {
  background-color: var(--grey);
}