.cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  margin-top: 80px;
  transition: color 0.3s ease;
  width: 100%;
}

.cartItems {
  overflow: auto;
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.cartItemsTable {
  border-collapse: collapse;
  table-layout: fixed;
  margin: 0px 5px;
  width: calc(100% - 20px);
  box-sizing: border-box;
  min-width: 350px;
}

.productHeader {
  text-align: left;
  flex-basis: 66.67%;
}

.cartItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

thead {
  width: 100%;
}

.headerRow {
  color: var(--text-color);
  padding: 8px;
  font-size: 18px;
  border-bottom: var(--border);
  display: flex;
  align-items: center;
}

.qtySubtotalWrap {
  display: flex;
  justify-content: space-between;
  flex-basis: 33.33%;
  min-width: 148px;
}

.quantity,
.subtotal {
  flex-basis: 50%;
}

.cartItemsTable td {
  padding: 0px 8px;
  font-size: 16px;
}

.cartItem .description {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  width: 80%;
  flex-basis: 66.67%;
}

.cartItem img {
  width: 40px;
  margin: 8px;
  margin-left: 0px;
  border-radius: 5px;
}

.cartItemprice {
  justify-content: center;
}

.counterContainer {
  display: flex;
  justify-content: space-between;
  flex-basis: 33.33%;
  min-width: 150px;
}

.countHandler {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 50%;
}

.countHandler button {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--text-color);
}

.countHandler input {
  width: 30px;
  text-align: center;
  margin: 0 5px;
  font-family: 'Event Horizon Medium';
}

.cartItemSubtotal {
  font-weight: bolder;
  padding: 0px 8px;
  text-align: center;
  font-size: 16px;
  flex-basis: 50%;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
}

.checkout button {
  font-family: 'Event Horizon Medium';
  width: 150px;
  height: 50px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: var(--border-bold);
  border-radius: 8px;
  margin: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
  box-shadow: var(--box-shadow-color);
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    border-color 0.3s ease;
}

.checkout button:hover {
  background-color: var(--text-color);
  color: var(--background-color);
  border: var(--border-bold);
}

.total,
.taxesNotIncluded {
  margin: 15px 2px;
}

.total {
  text-align: center;
  font-size: larger;
}

.taxesNotIncluded {
  font-size: smaller;
}

.cartTotal {
  display: flex;
  align-items: center;
  justify-content: center;
}
