.paymentStatus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paymentNotification {
  margin-bottom: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
}

.paymentStatus button {
  font-family: 'Event Horizon Medium';
  width: 150px;
  height: 50px;
  background-color: var(--text-color);
  color: var(--background-color);
  border: none;
  border-radius: 8px;
  margin: 10px;
  cursor: pointer;
  transition:
    color 0.3s ease,
    background-color 0.3s ease;
}
